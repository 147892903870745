import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
// Uncomment the following line if you plan to use Analytics in the future
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC23HGSqusyr3HCnM5ONt0i4stxr4FvVhI",
  authDomain: "orchestral-ai-frontend.firebaseapp.com",
  projectId: "orchestral-ai-frontend",
  storageBucket: "orchestral-ai-frontend.firebasestorage.app",
  messagingSenderId: "557731818490",
  appId: "1:557731818490:web:59ec30519457bb42281243",
  measurementId: "G-DWL4ZZD9T6", // Optional, needed for Analytics
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Comment out this line if Analytics is not being used
// const analytics = getAnalytics(app);
const auth = getAuth(app);

// Google Sign-In
const provider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  signInWithPopup(auth, provider).catch((error) => {
    console.error('Error during sign-in:', error);
  });
};

export { auth, signInWithGoogle, signOut /* analytics if needed */ };