import React, { useState, useEffect } from 'react';
import { auth, signInWithGoogle } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Chat from './Chat';
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  if (user) {
    // User is signed in, show the chat interface
    return <Chat user={user} />;
  } else {
    // User is not signed in, show the landing page with "Get Started" button
    return (
      <div className="App">
        <header className="App-header">
          <h1>Orchestral AI</h1>
          <p>Building the future of AI collaboration.</p>
          <button className="btn" onClick={signInWithGoogle}>
            Get Started
          </button>
        </header>
      </div>
    );
  }
}

export default App;