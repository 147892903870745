import React, { useState, useEffect, useRef } from "react";
import "./Chat.css";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";

function Chat({ user }) {
  const [messages, setMessages] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [socket, setSocket] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const streamedMessageRef = useRef("");
  const [streamedContent, setStreamedContent] = useState(""); // Tracks streamed chunks for real-time display
  const textAreaRef = useRef(null);

  // Debug: Log when component mounts/unmounts
  useEffect(() => {
    console.log("Chat component mounted");
    return () => console.log("Chat component unmounted");
  }, []);

  useEffect(() => {
    console.log("Initializing WebSocket connection...");

    // Fetch the Firebase ID token and initialize WebSocket
    auth.currentUser.getIdToken().then((idToken) => {
      console.log("ID Token fetched:", idToken);

      const ws = new WebSocket(`wss://9540-2603-9000-f100-87c4-8463-50c9-aa6b-1d85.ngrok-free.app/ws?token=${idToken}`);
      setSocket(ws);

      ws.onopen = () => {
        console.log("WebSocket connection established");
        setIsConnected(true);
      };

      ws.onmessage = (event) => {
        const chunk = event.data;

        if (chunk.trim() === "[END OF STREAM]") {
          console.log("Streaming complete");

          // Preserve the streamed content before resetting
          const finalStreamedContent = streamedMessageRef.current;

          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "assistant", text: finalStreamedContent }
          ]);

          // Clear after appending the final message
          streamedMessageRef.current = "";
          setStreamedContent("");
          setStreaming(false);
        } else {
          streamedMessageRef.current += chunk;
          setStreamedContent(streamedMessageRef.current);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed unexpectedly.");
        setIsConnected(false);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setIsConnected(false);
      };

      return () => {
        console.log("Cleaning up WebSocket connection...");
        ws.close();
      };
    });
  }, []);

  useEffect(() => {
    // console.log("Messages updated:", messages);
  }, [messages]);

  const handleSendMessage = () => {
    if (textInput.trim() === "" || streaming || !isConnected) {
      console.log("Message not sent. Validation failed. Input:", textInput, "Streaming:", streaming, "Connected:", isConnected);
      return;
    }

    console.log("Sending message:", textInput);

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, { sender: "user", text: textInput }];
      console.log("Updated messages:", updatedMessages);
      return updatedMessages;
    });

    streamedMessageRef.current = "";
    setStreamedContent("");

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(textInput);
      console.log("Message sent to server");
      setStreaming(true);
    } else {
      console.error("WebSocket is not ready");
    }

    setTextInput("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSignOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const maxHeight = 200;
      textarea.style.height = Math.min(textarea.scrollHeight, maxHeight) + "px";
    }
  }, [textInput]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2>Chat</h2>
        <button className="btn" onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
      <div id="chatBox" className="chat-box">
        {messages.length === 0 && <div>No messages yet</div>}
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
          </div>
        ))}
        {streaming && <div className="message assistant">{streamedContent}</div>}
      </div>
      <div id="inputBox" className="input-box">
        <textarea
          id="textInput"
          ref={textAreaRef}
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          placeholder="Type a message..."
          rows={2}
          onKeyDown={handleKeyDown}
          className="text-area"
        ></textarea>
        <button
          id="sendButton"
          className="btn send-button"
          onClick={handleSendMessage}
          disabled={streaming || textInput.trim() === ""}
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default Chat;
