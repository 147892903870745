import React from 'react';
// import NavBar from './NavBar';

import './App.css';

function About() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>About Orchestral AI</h1>
        <p>Building the future of AI collaboration.</p>
      </header>
      <div className="container">
        <h2>Our Mission</h2>
        <p>
          At Orchestral AI, we believe in advancing civilization by enabling
          seamless AI collaboration. Inspired by Alfred North Whitehead's idea
          that "Civilization advances by extending the number of important
          operations which we can perform without thinking about them," we aim
          to redefine AI systems to tackle complex tasks.
        </p>
        <h2>Meet the Team</h2>
        <div className="team">
          <div className="team-member">
            <img
              src="build/assets/headshots/alex_roman.png"
              alt="Dr. Alex Roman"
              className="team-photo"
            />
            <h3>Dr. Alex Roman</h3>
            <p>
              Alex specializes in algorithm development, experimentation, and
              applied AI research. With a PhD in Physics and extensive
              experience building AI-enhanced systems, he brings innovative
              solutions to Orchestral AI.
            </p>
          </div>
          <div className="team-member">
            <img
              src="build/assets/headshots/jake_roman.png"
              alt="Jake Roman"
              className="team-photo"
            />
            <h3>Jake Roman</h3>
            <p>
              Jake focuses on multi-agent systems and prompt engineering. As a
              Computer Science student, he blends academic knowledge with
              practical expertise to develop robust AI collaboration tools.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
